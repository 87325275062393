import React, { useState } from "react";
import { Text } from "@your-org/ui-lib";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";
import { MainLayout } from "@/components/layout";

function SignInPage(): JSX.Element {
  const router = useRouter();
  const [mail, setMail] = useState("");
  const [loading, setLoading] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const [showVerify, setShowVerify] = useState(false);

  async function handleSubmit(e: any) {
    e.preventDefault();
    const email = e.target[0].value;

    setMail(email);
    setLoading(true);
    const signInResponse = await signIn("email", { email, redirect: false, callbackUrl: "/" });
    setLoading(false);

    const { error, url, ok, status } = signInResponse || {};
    console.log({ error, url, ok, status });

    // todo idk if this is correct
    if (error) {
      if (url) {
        router.push(url);
      } else if (error === "AccessDenied") setAccessDenied(true);
    } else {
      setShowVerify(true);
    }
  }

  if (accessDenied) return <MainLayout headerText="access Denied" bg="bg-yellow-light">
    <div className="flex items-center justify-center">
      <Text variant="big">✋</Text>
    </div>
  </MainLayout>;

  if (showVerify) return <MainLayout headerText="Accept Magic Link" bg="bg-yellow-light">
    <Text variant="h3" className="pl-28 text-black">Click the link in the email we sent you</Text>
  </MainLayout>;

  return (
    <MainLayout headerText="login" bg="bg-yellow-light">
      <div className="flex items-center justify-center h-full">
        <form
          method="post"
          onSubmit={handleSubmit}
        >
          <div className="w-64 flex flex-col items-end">
            <input
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              className="w-full px-2 my-2 py-2 block border-0 border-b-2 border-black border-solid dark:text-black text-black placeholder-black placeholder-opacity-30 no-autofill-bkg bg-opacity-0"
              placeholder="EMAIL"
              type="email"
              id="email"
              name="email"
              required={true}
            />
            {!loading ? (
              <button className="uppercase text-black" type="submit">
                <Text underline="h1" variant="menu">
                  send magic link →
                </Text>
              </button>
            ) : <Text className="uppercase text-black">loading</Text>}
          </div>
        </form>
      </div>
    </MainLayout>
  );
}

export default SignInPage;
